import React from 'react';

import {
    Link,
} from 'react-router-dom';


class Header extends React.Component{
    render(){
        return (
            <nav>
                <ul className="navbar">
                    <li className="navbar">
                    <Link to="/"><img src="./logo.svg" alt="kfsc-Logo" height="28" width="37" style={{verticalAlign : "middle"}}/></Link>
                    </li>
                    <li className="navbar">
                        <Link to="/leistungen">leistungen</Link>
                    </li>
                    <li className="navbar">
                        <Link to="/profil">profil</Link>
                    </li>
                    <li className="navbar">
                        <Link to="/kontakt">kontakt</Link>
                    </li>
                    <li className="navbar" style={{float: "right"}}>
                        <Link to="/impressum">impressum</Link>
                    </li>
                    <li className="navbar" style={{float: "right"}}>
                        <Link to="/datenschutz">datenschutz</Link>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default Header;
