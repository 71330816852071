import React from 'react';

class ContactForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            subject: '',
            email: '',
            message: '',
            errorMessage: '',
            successMessage: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async post(url, data) {
        this.setState({
            errorMessage: '',
            successMessage: ''
        });
        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        }).then(([statusCode, data]) => {
            switch (statusCode) {
                case 200:
                    this.setState({
                        name: '',
                        subject: '',
                        email: '',
                        message: '',
                        successMessage: data.message
                    });
                    break;
                default:
                    this.setState({
                        errorMessage: data.message
                    });
                    break;
            }
        }).catch(error => {
            const msg = "Die Nachricht konnte leider nicht versendet werden. Bitte versuchen Sie es später noch einmal.";
            this.setState({
               errorMessage: msg 
            });
        });
    }

    handleChange(event) {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.post('./api/contact', this.state);
    }

    render() {
        return (
                <div>
                {this.state.successMessage && <div className="success-message">{this.state.successMessage}</div>}
                {this.state.errorMessage && <div className="error-message">{this.state.errorMessage}</div>}
                <form onSubmit={this.handleSubmit} >
                    <label className="form-label">Name</label>
                    <input className="form-input" type="text" required="true" name="name" value={this.state.name} onChange={this.handleChange} />
                    <label className="form-label">Email</label>
                    <input className="form-input" type="email" required="true" name="email" value={this.state.email} onChange={this.handleChange} />
                    <div className="form-spacer"></div>
                    <label className="form-label">Betreff</label>
                    <input className="form-input" type="text" required="true" name="subject" value={this.state.subject} onChange={this.handleChange} />
                    <label className="form-label">Nachricht</label>
                    <textarea className="form-textarea" rows="10" required="true" name="message" value={this.state.message} onChange={this.handleChange} />
                    <div className="form-spacer"></div>
                    <input className="form-button" type="submit" value="Senden" />
                </form>
                </div>
                );
    }
}


export default ContactForm;
