
import javaicon from '../icons/java.png'
import cppicon from '../icons/cpp_logo.png'
import perlicon from '../icons/perl.png'
import pythonicon from '../icons/python.png'
import bashicon from '../icons/bash.png'
import springicon from '../icons/spring.png'
import jenkinsicon from '../icons/jenkins.png'
import jiraicon from '../icons/jira.png'
import giticon from '../icons/git.png'
import debianicon from '../icons/debian.svg'
import mariadbicon from '../icons/mariadb.svg'
import scrumicon from '../icons/scrum.png'
import reacticon from '../icons/react.svg'
import angularicon from '../icons/angular.png'
import freertosicon from '../icons/freertos.png'
import avricon from '../icons/avr-logo.svg'
import stmicon from '../icons/st-logo.svg'
import armicon from '../icons/arm-logo.svg'
import raspiicon from '../icons/raspberry-pi-logo.svg'
import nextcloudicon from '../icons/nextcloud-logo.svg'
import mediawikiicon from '../icons/mediawiki-logo.png'
import beagleboneicon from '../icons/beagleboard-embedded.svg'
import emailicon from '../icons/email.png'
import bind9icon from '../icons/bind9.png'
import apacheicon from '../icons/apache.png'
import tomcaticon from '../icons/tomcat.png'
import networkicon from '../icons/network.png'
import javascripticon from '../icons/javascript.svg'

const skillData = [
    {
        category: "Programmier&shy;sprachen",
        skills: [
            {
                skill: "Java",
                level: "+++",
                icon: javaicon,
                comment: "Über 20 Jahre Erfahrung"
            },
            {
                skill: "C++",
                level: "+++",
                icon: cppicon,
                comment: "Über 20 Jahre Erfahrung"
            },
            {
                skill: "Perl",
                level: "++",
                icon: perlicon,
                comment: ""
            },
            {
                skill: "Python",
                level: "++",
                icon: pythonicon,
                comment: ""
            },
            {
                skill: "Bash",
                level: "+++",
                icon: bashicon,
                comment: "Seit über 20 Jahren im täglichen Einsatz für Administration, Softwareentwicklung und benutzerspezifische Anpassungen des Desktops"
            },
            {
                skill: "JavaScript",
                level: "++",
                icon: javascripticon,
                comment: ""
            }
        ]
    },
    {
        category: "Frameworks",
        skills: [
            {
                skill: "Spring",
                level: "+++",
                icon: springicon,
                comment: ""
            },
            {
                skill: "JPA / Hibernate / Spring Data",
                level: "+++",
                icon: springicon,
                comment: ""
            },
            {
                skill: "React JS",
                level: "+",
                icon: reacticon,
                comment: ""
            },
            {
                skill: "AngularJS",
                level: "+",
                icon: angularicon,
                comment: ""
            }
        ]
    },
    {
        category: "DevOps und Kollaboration",
        skills: [
            {
                skill: "Jenkins",
                level: "++",
                icon: jenkinsicon,
                comment: "Anwender und Administrator. Betrieb eigener Infrastruktur zur Softwareentwicklung"
            },
            {
                skill: "git",
                level: "+++",
                icon: giticon,
                comment: "Anwender, Administrator und Trainer. Betrieb eigener Infrastruktur zur Softwareentwicklung"
            },
            {
                skill: "Atlassian Jira",
                level: "++",
                icon: jiraicon,
                comment: "Kundensupport und Betrieb eigener Infrastruktur. Erfahrung in allen Projektrollen, sowie als Administrator."
            },
            {
                skill: "Mediawiki",
                level: "++",
                icon: mediawikiicon,
                comment: "Kundensupport und Betrieb eigener Infrastruktur"
            },
            {
                skill: "Nextcloud",
                level: "++",
                icon: nextcloudicon,
                comment: "Kundensupport und Betrieb eigener Infrastruktur"
            },
            {
                skill: "Agile Methoden / Scrum",
                level: "+++",
                icon: scrumicon,
                comment: "Einführung agiler Methoden in kleinen heterogenen Teams. Unterstützung als Developer, Product Owner oder Scrum Master."
            }
        ]
    },
    {
        category: "Embedded µ-Controller",
        skills: [
            {
                skill: "Atmel",
                level: "++",
                icon: avricon,
                comment: "Alle AVR-MCUs von ATtiny bis ATmega"
            },
            {
                skill: "STM32",
                level: "++",
                icon: stmicon,
                comment: "Einsatz in der Gebäudeautomatisierung"
            },
            {
                skill: "ARM-basierte Ein&shy;pla&shy;ti&shy;nen&shy;rechner",
                level: "+++",
                icon: armicon,
                comment: "Nutzung in verschiedenen Projekten mit Debian/Raspbian Linux"
            },
            {
                skill: "Beaglebone Black",
                level: "+++",
                icon: beagleboneicon,
                comment: "Projekte in der Echtzeitsignalerfassung"
            },
            {
                skill: "Raspberry Pi",
                level: "+++",
                icon: raspiicon,
                comment: "Projekte in der Gebäudeautomatisierung, sowie als Mediaplayer"
            }
        ]
    },
    {
        category: "Betriebs&shy;systeme und Dienste",
        skills: [
            {
                skill: "Debian GNU/Linux",
                level: "+++",
                icon: debianicon,
                comment: "Aktiver Einsatz auf Plattformen aller Größen: Embedded (IPC, Beaglebone, Raspberry Pi), Desktop, Server bis RZ. Debian GNU/Linux auf Server und Desktop seit über 20 Jahren, Embedded seit über 10 Jahren"
            },
            {
                skill: "MariaDB / MySQL",
                level: "+++",
                icon: mariadbicon,
                comment: "Erfahrung als Entwickler und Administrator seit über 20 Jahren im unteren Big-Data-Bereich"
            },
            {
                skill: "FreeRTOS",
                level: "++",
                icon: freertosicon,
                comment: "Auf STM32 in der Gebäudeautomatisierung eingesetzt"
            },
            {
                skill: "E-Mail-Dienste (SMTP, IMAP)",
                level: "+++",
                icon: emailicon,
                comment: "Betrieb eigener Infrastruktur (Postgres, dovecot) und Kundensupport seit über 20 Jahren"
            },
            {
                skill: "Domaindienste (DNS/bind9)",
                level: "+++",
                icon: bind9icon,
                comment: "Betrieb eigener Infrastruktur und Unterhaltung eigener Domains, sowie Kundensupport seit über 20 Jahren"
            },
            {
                skill: "Apache HTTP Server",
                level: "+++",
                icon: apacheicon,
                comment: "Betrieb eigener Infrastruktur, sowie Kundensupport seit über 20 Jahren"
            },
            {
                skill: "Apache Tomcat",
                level: "+++",
                icon: tomcaticon,
                comment: "Betrieb eigener Infrastruktur, sowie Kundensupport"
            },
            {
                skill: "Networking",
                level: "+++",
                icon: networkicon,
                comment: "Konzeption, Aufbau und Betrieb von Firmen-Intranetstrukturen mit DMZ-basiertem Firewalling. Infrastrukturverantwortung bei früheren Arbeitgebern, sowie Kundensupport seit über 20 Jahren"
            }
        ]
    }];

export default skillData;
