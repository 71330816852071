import React from 'react';
import PropTypes from 'prop-types';

const addQuotes = (s) => '"' + s + '"';

const renderMessage = (message) => message? addQuotes(message) : '';
const renderAuthor = (author) => author? '- ' + author : '';

const Quote = ( { message, author } ) => (
            <div className="quote">
                <div className="message">{renderMessage(message)}</div>
                <div className="author">{renderAuthor(author)}</div>
            </div>
        );

Quote.propTypes = {
    message: PropTypes.string,
    author: PropTypes.string
};

export default Quote;
