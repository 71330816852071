import React from 'react';
import { connect } from 'react-redux';
import Quote from './Quote';
import { updateQuote, getQuote } from './store/Actions';
import { bindActionCreators } from 'redux';

const fetchQuotesAction = () => {
    return dispatch => {
        fetch('./api/randomquote')
                .then(response => response.json())
                .then(result => dispatch(updateQuote(result.payload)))
                .catch(e => console.log(e));
    };
};

class RawQuoteContainer extends React.Component {

    componentDidMount() {
        const { fetchQuotes, quote } = this.props;
        (quote && quote.message) || fetchQuotes();
    }

    render = () => {
            return <Quote {...this.props.quote} />;
    };
};

const mapStateToProps = state => ({
    quote: getQuote(state)
  });

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchQuotes: fetchQuotesAction
}, dispatch);


const QuoteContainer = connect(mapStateToProps, mapDispatchToProps)(RawQuoteContainer);
export default QuoteContainer;
