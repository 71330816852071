import React from 'react';
import SmallLogo from './SmallLogo';
import SkillTable from './SkillTable';
import skillData from './modules/SkillData.js'
import About from './About.js'

class Profile extends React.Component{
    render(){
        return (
            <React.Fragment>
                <SmallLogo />
                    <About />
                    <SkillTable title="Technische Fähigkeiten" skills={skillData} />
            </React.Fragment>
        );
    }
};

export default Profile;
