import React from 'react';
import SmallLogo from './SmallLogo';

class Impressum extends React.Component{
    render(){
        return (
            <div>
                <SmallLogo />
                <p style={{textAlign: "center"}}>
                Kai Fritzowsky<br />
                Gottesauer Str. 10<br />
                76131 Karlsruhe<br />
                <br />
                USt-IdNr. DE308833388<br />
                <br />
                info (at) fritzows.ky<br />
                +49 176 6080 5501</p>
            </div>
        )
    }
}

export default Impressum;
