
import React from 'react';

const About = () => (
        <React.Fragment>
            <div className="about">
                <p>"Ich entwickle leidenschaftlich gern Software 
                und finde kreative Lösungen für ungewöhnliche Herausforderungen.
                <br />
                Als IT-Enthusiast betreibe ich benötigte Infrastruktur selbst und 
                gebe mein Wissen gerne in Workshops weiter."</p>
            </div>
        </React.Fragment>
        );

export default About;
    