
import { 
    createStore,
    combineReducers,
    applyMiddleware
} from 'redux';

import thunk from 'redux-thunk';

import {
    quote
} from './reducers';

const middlewares = [thunk];


const storeFactory = (initialState={}) => (
        createStore(
            combineReducers({
                quote
            }),
            initialState,
            applyMiddleware(...middlewares))
        );

export default storeFactory;
