import React from 'react';
import SmallLogo from './SmallLogo';
import ServiceTable from './ServiceTable';
import serviceData from './modules/ServiceData.js'
import About from './About.js'

const Catchphrase = () => (
        <div className="about">
        <p>Ihr Projekt zu Ihrem Erfolg zu machen,
        <br />das ist meine Aufgabe.</p>
        </div>
        );

class Services extends React.Component{
    render(){
        return (
            <React.Fragment>
                <SmallLogo />
                    <Catchphrase />
                    <ServiceTable title="Dienstleistungen" services={serviceData} />
            </React.Fragment>
        );
    }
};

export default Services;
