import React from 'react';
import SmallLogo from './SmallLogo';

const Datenschutz = () => (
            <div className="privacypolicy">
                <SmallLogo />
                <h1>Datenschutz</h1>
                <ol>
                    <li>
                        <h2>Name und Anschrift des Verantwortlichen</h2>

                        Verantwortlicher im Sinne der Datenschutz-Grundverordnung und
                        anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie
                        sonstiger datenschutzrechtlicher Bestimmungen ist:<br />
                        <br />
                        Kai Fritzowsky<br />
                        Gottesauer Str. 10<br />
                        76131 Karlsruhe<br />
                        Deutschland<br />
                        Tel.: +49 176 6080 5501<br />
                        E-Mail: datenschutz@fritzows.ky<br />
                        Website: www.fritzows.ky
                    </li>
                    <li>
                        <h2>Allgemeines zur Datenverarbeitung</h2>

                        <ol>
                            <li>
                                <h3>Umfang der Verarbeitung personenbezogener Daten</h3>

                                Personenbezogene Daten verarbeiten wir nur, soweit dies zur
                                Bereitstellung einer funktionsfähigen Website sowie zur
                                Erbringung unserer Dienstleistungen nötig ist. Die
                                Verarbeitung personenbezogener Daten erfolgt regelmäßig nur
                                nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen
                                Fällen, in denen eine vorherige Einholung einer Einwilligung
                                aus tatsächlichen Gründen nicht möglich ist und die
                                Verarbeitung der Daten durch gesetzliche Vorschriften
                                gestattet ist.
                            </li>

                            <li>
                                <h3>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h3>

                                Soweit wir für Verarbeitungsvorgänge personenbezogener Daten
                                eine Einwilligung der betroffenen Person einholen, dient
                                Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als
                                Rechtsgrundlage.  Bei der Verarbeitung von personenbezogenen
                                Daten, die zur Erfüllung eines Vertrages, dessen
                                Vertragspartei die betroffene Person ist, erforderlich ist,
                                dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies
                                gilt auch für Verarbeitungsvorgänge, die zur Durchführung
                                vorvertraglicher Maßnahmen erforderlich sind. Soweit eine
                                Verarbeitung personenbezogener Daten zur Erfüllung einer
                                rechtlichen Verpflichtung erforderlich ist, der unser
                                Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
                                Rechtsgrundlage.  Für den Fall, dass lebenswichtige Interessen
                                der betroffenen Person oder einer anderen natürlichen Person
                                eine Verarbeitung personenbezogener Daten erforderlich machen,
                                dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.  Ist die
                                Verarbeitung zur Wahrung eines berechtigten Interesses unseres
                                Unternehmens oder eines Dritten erforderlich und überwiegen
                                die Interessen, Grundrechte und Grundfreiheiten des
                                Betroffenen das erstgenannte Interesse nicht, so dient Art. 6
                                Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
                            </li>

                            <li>
                                <h3>Datenlöschung und Speicherdauer</h3>

                                Die personenbezogenen Daten der betroffenen Person werden
                                gelöscht oder gesperrt, sobald der Zweck der Speicherung
                                entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn
                                dies durch den europäischen oder nationalen Gesetzgeber in
                                unionsrechtlichen Verordnungen, Gesetzen oder sonstigen
                                Vorschriften, denen der Verantwortliche unterliegt, vorgesehen
                                wurde. Eine Sperrung oder Löschung der Daten erfolgt auch
                                dann, wenn eine durch die genannten Normen vorgeschriebene
                                Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit
                                zur weiteren Speicherung der Daten für einen Vertragsabschluss
                                oder eine Vertragserfüllung besteht.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <h2>Bereitstellung der Website und Erstellung von Logfiles</h2>

                        <ol>
                            <li>
                                <h3>Beschreibung und Umfang der Datenverarbeitung</h3>

                                Bei jedem Aufruf meiner Internetseite erfasst unser System
                                automatisiert Daten und Informationen vom Computersystem des
                                aufrufenden Rechners.  Folgende Daten werden hierbei erhoben:
                                <ol className="enum">
                                    <li>Informationen über den Browsertyp und die verwendete Version</li>
                                    <li>Das Betriebssystem des Nutzers</li>
                                    <li>Die IP-Adresse des Nutzers</li>
                                    <li>Datum und Uhrzeit des Zugriffs</li>
                                    <li>Websites, von denen das System des Nutzers auf meine Internetseite gelangt</li>
                                    <li>Websites, die vom System des Nutzers über meine Website aufgerufen werden</li>
                                </ol>
                                Die Daten werden ebenfalls in den Logfiles meines Systems
                                gespeichert. Nicht hiervon betroffen sind die IP-Adressen des
                                Nutzers oder andere Daten, die die Zuordnung der Daten zu
                                einem Nutzer ermöglichen. Eine Speicherung dieser Daten
                                zusammen mit anderen personenbezogenen Daten des Nutzers
                                findet nicht statt.
                            </li>

                            <li>
                                <h3>Rechtsgrundlage für die Datenspeicherung</h3>

                                Rechtsgrundlage für die vorübergehende Speicherung der Daten ist Art. 6 Abs. 1 lit. f DSGVO.
                            </li>

                            <li>
                                <h3>Zweck der Datenverarbeitung</h3>

                                Die vorübergehende Speicherung der IP-Adresse durch das System
                                ist notwendig, um eine Auslieferung der Website an den Rechner
                                des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des
                                Nutzers für die Dauer der Sitzung gespeichert bleiben.
                                <br />
                                In diesen Zwecken liegt auch unser berechtigtes Interesse an
                                der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
                            </li>

                            <li>
                                <h3>Dauer der Speicherung</h3>

                                Die Daten werden gelöscht, sobald sie für die Erreichung des
                                Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle
                                der Erfassung der Daten zur Bereitstellung der Website ist
                                dies der Fall, wenn die jeweilige Sitzung beendet ist.
                            </li>

                            <li>
                                <h3>Widerspruchs- und Beseitigungsmöglichkeit</h3>

                                Die Erfassung der Daten zur Bereitstellung der Website und die
                                Speicherung der Daten in Logfiles ist für den Betrieb der
                                Internetseite zwingend erforderlich. Es besteht folglich
                                seitens des Nutzers keine Widerspruchsmöglichkeit.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <h2>Kontaktformular und E-Mail-Kontakt</h2>

                        <ol>
                            <li>
                                <h3>Beschreibung und Umfang der Datenverarbeitung</h3>

                                Auf unserer Internetseite sind die Kontaktdaten
                                veröffentlicht, welche für die elektronische Kontaktaufnahme
                                genutzt werden können.
                                <br />
                                Im Falle der Kontaktaufnahme per E-Mail oder Kontaktformular
                                werden die mit der E-Mail oder dem Kontaktformular übermittelten
                                personenbezogenen Daten des Nutzers gespeichert.
                            </li>

                            <li>
                                <h3>Rechtsgrundlage für die Datenverarbeitung</h3>

                                Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge
                                einer Übersendung einer E-Mail übermittelt werden, ist Art. 6
                                Abs. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf den
                                Abschluss eines Vertrages ab, so ist zusätzliche
                                Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b
                                DSGVO.
                            </li>

                            <li>
                                <h3>Zweck der Datenverarbeitung</h3>

                                Im Falle einer Kontaktaufnahme per E-Mail oder Kontaktformular
                                dient die Verarbeitung der personenbezogenen Daten allein zur
                                Bearbeitung der Kontaktaufnahme. Hierin liegt auch das
                                erforderliche berechtigte Interesse an der Verarbeitung der
                                Daten.
                            </li>

                            <li>
                                <h3>Dauer der Speicherung</h3>

                                Die Daten werden gelöscht, sobald sie für die Erreichung des
                                Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die
                                personenbezogenen Daten, die per E-Mail übersandt wurden, ist
                                dies dann der Fall, wenn die jeweilige Konversation mit dem
                                Nutzer beendet ist. Beendet ist die Konversation dann, wenn
                                sich aus den Umständen entnehmen lässt, dass der betroffene
                                Sachverhalt abschließend geklärt ist.
                            </li>

                            <li>
                                <h3>Widerspruchs- und Beseitigungsmöglichkeit</h3>

                                Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung
                                zur Verarbeitung der personenbezogenen Daten zu
                                widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit mir auf,
                                so kann er der Speicherung seiner personenbezogenen Daten
                                jederzeit widersprechen. In einem solchen Fall kann die
                                Konversation nicht fortgeführt werden.
                                <br />
                                Der Widerspruch kann schriftlich an den oben genannten
                                Datenschutzverantwortlichen gerichtet werden.
                                <br />
                                Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme
                                gespeichert wurden, werden in diesem Fall gelöscht.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <h2>Rechte der betroffenen Person</h2>

                        Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
                        Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte
                        gegenüber dem Verantwortlichen zu:

                        <ol>
                            <li>
                                <h3>Auskunftsrecht</h3>

                                Sie können von dem Verantwortlichen eine Bestätigung darüber
                                verlangen, ob personenbezogene Daten, die Sie betreffen, von
                                uns verarbeitet werden.
                                <br />
                                Liegt eine solche Verarbeitung vor, können Sie von dem
                                Verantwortlichen über folgende Informationen Auskunft
                                verlangen:

                                <ol className="enum">
                                    <li>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
                                    <li>die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
                                    <li>die Empfänger bzw. die Kategorien von Empfängern,
                                        gegenüber denen die Sie betreffenden personenbezogenen Daten
                                        offengelegt wurden oder noch offengelegt werden;</li>
                                    <li>
                                        die geplante Dauer der Speicherung der Sie betreffenden
                                        personenbezogenen Daten oder, falls konkrete Angaben
                                        hierzu nicht möglich sind, Kriterien für die Festlegung
                                        der Speicherdauer;</li>
                                    <li>das Bestehen eines Rechts auf Berichtigung oder Löschung
                                        der Sie betreffenden personenbezogenen Daten, eines Rechts
                                        auf Einschränkung der Verarbeitung durch den
                                        Verantwortlichen oder eines Widerspruchsrechts gegen diese
                                        Verarbeitung;</li>
                                    <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
                                    <li>alle verfügbaren Informationen über die Herkunft der
                                        Daten, wenn die personenbezogenen Daten nicht bei der
                                        betroffenen Person erhoben werden;</li>
                                    <li>das Bestehen einer automatisierten Entscheidungsfindung
                                        einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO
                                        und – zumindest in diesen Fällen – aussagekräftige
                                        Informationen über die involvierte Logik sowie die
                                        Tragweite und die angestrebten Auswirkungen einer
                                        derartigen Verarbeitung für die betroffene Person.  Ihnen
                                        steht das Recht zu, Auskunft darüber zu verlangen, ob die
                                        Sie betreffenden personenbezogenen Daten in ein Drittland
                                        oder an eine internationale Organisation übermittelt
                                        werden. In diesem Zusammenhang können Sie verlangen, über
                                        die geeigneten Garantien gem. Art. 46 DSGVO im
                                        Zusammenhang mit der Übermittlung unterrichtet zu werden.</li>
                                </ol>
                            </li>
                            <li>
                                <h3>Recht auf Berichtigung</h3>

                                Sie haben ein Recht auf Berichtigung und/oder
                                Vervollständigung gegenüber dem Verantwortlichen, sofern die
                                verarbeiteten personenbezogenen Daten, die Sie betreffen,
                                unrichtig oder unvollständig sind. Der Verantwortliche hat die
                                Berichtigung unverzüglich vorzunehmen.
                            </li>

                            <li>
                                <h3>Recht auf Einschränkung der Verarbeitung</h3>

                                Unter den folgenden Voraussetzungen können Sie die
                                Einschränkung der Verarbeitung der Sie betreffenden
                                personenbezogenen Daten verlangen:

                                <ol className="enum">
                                    <li> wenn Sie die Richtigkeit der Sie betreffenden
                                        personenbezogenen für eine Dauer bestreiten, die es dem
                                        Verantwortlichen ermöglicht, die Richtigkeit der
                                        personenbezogenen Daten zu überprüfen;</li>
                                    <li>
                                        die Verarbeitung unrechtmäßig ist und Sie die Löschung der
                                        personenbezogenen Daten ablehnen und stattdessen die
                                        Einschränkung der Nutzung der personenbezogenen Daten
                                        verlangen;</li>
                                    <li>
                                        der Verantwortliche die personenbezogenen Daten für die
                                        Zwecke der Verarbeitung nicht länger benötigt, Sie diese
                                        jedoch zur Geltendmachung, Ausübung oder Verteidigung von
                                        Rechtsansprüchen benötigen, oder</li>
                                    <li>
                                        wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21
                                        Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob
                                        die berechtigten Gründe des Verantwortlichen gegenüber
                                        Ihren Gründen überwiegen.</li>
                                </ol>

                                Wurde die Verarbeitung der Sie betreffenden personenbezogenen
                                Daten eingeschränkt, dürfen diese Daten – von ihrer
                                Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
                                Geltendmachung, Ausübung oder Verteidigung von
                                Rechtsansprüchen oder zum Schutz der Rechte einer anderen
                                natürlichen oder juristischen Person oder aus Gründen eines
                                wichtigen öffentlichen Interesses der Union oder eines
                                Mitgliedstaats verarbeitet werden.
                                <br />
                                Wurde die Einschränkung der Verarbeitung nach den
                                o.g. Voraussetzungen eingeschränkt, werden Sie von dem
                                Verantwortlichen unterrichtet bevor die Einschränkung
                                aufgehoben wird.
                            </li>

                            <li>
                                <h3>Recht auf Löschung</h3>

                                <ol>
	                                <li>
                                        <h4>Löschungspflicht</h4>

                                        Sie können von dem Verantwortlichen verlangen, dass die
                                        Sie betreffenden personenbezogenen Daten unverzüglich
                                        gelöscht werden, und der Verantwortliche ist verpflichtet,
                                        diese Daten unverzüglich zu löschen, sofern einer der
                                        folgenden Gründe zutrifft:

                                        <ol className="enum">
                                            <li>
                                            Die Sie betreffenden personenbezogenen Daten sind für
                                            die Zwecke, für die sie erhoben oder auf sonstige
                                            Weise verarbeitet wurden, nicht mehr notwendig.</li>
                                            <li>Sie widerrufen Ihre Einwilligung, auf die sich die
                                            Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9
                                            Abs. 2 lit. a DSGVO stützte, und es fehlt an einer
                                            anderweitigen Rechtsgrundlage für die Verarbeitung.</li>
                                            <li>Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch
                                            gegen die Verarbeitung ein und es liegen keine
                                            vorrangigen berechtigten Gründe für die Verarbeitung
                                            vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO
                                            Widerspruch gegen die Verarbeitung ein.</li>
                                            <li>Die Sie betreffenden personenbezogenen Daten wurden
                                            unrechtmäßig verarbeitet.</li>
                                            <li>Die Löschung der Sie betreffenden personenbezogenen
                                            Daten ist zur Erfüllung einer rechtlichen
                                            Verpflichtung nach dem Unionsrecht oder dem Recht der
                                            Mitgliedstaaten erforderlich, dem der Verantwortliche
                                            unterliegt.</li>
                                            <li>Die Sie betreffenden personenbezogenen Daten wurden
                                            in Bezug auf angebotene Dienste der
                                            Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO
                                            erhoben.</li>
                                        </ol>
                                    </li>

                                    <li>
                                        <h4>Ausnahmen</h4>

                                        Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist

                                        <ol className="enum">
                                            <li>
                                            zur Ausübung des Rechts auf freie Meinungsäußerung und
                                            Information;</li>
                                            <li>
                                            zur Erfüllung einer rechtlichen Verpflichtung, die die
                                            Verarbeitung nach dem Recht der Union oder der
                                            Mitgliedstaaten, dem der Verantwortliche unterliegt,
                                            erfordert, oder zur Wahrnehmung einer Aufgabe, die im
                                            öffentlichen Interesse liegt oder in Ausübung
                                            öffentlicher Gewalt erfolgt, die dem Verantwortlichen
                                            übertragen wurde;</li>
                                            <li>aus Gründen des öffentlichen Interesses im Bereich
                                            der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h
                                            und i sowie Art. 9 Abs. 3 DSGVO;</li>
                                            <li>für im öffentlichen Interesse liegende Archivzwecke,
                                            wissenschaftliche oder historische Forschungszwecke
                                            oder für statistische Zwecke gem. Art. 89 Abs. 1
                                            DSGVO, soweit das unter Abschnitt a) genannte Recht
                                            voraussichtlich die Verwirklichung der Ziele dieser
                                            Verarbeitung unmöglich macht oder ernsthaft
                                            beeinträchtigt, oder</li>
                                            <li>zur Geltendmachung, Ausübung oder Verteidigung von
                                            Rechtsansprüchen.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <h3>Recht auf Unterrichtung</h3>

                                Haben Sie das Recht auf Berichtigung, Löschung oder
                                Einschränkung der Verarbeitung gegenüber dem Verantwortlichen
                                geltend gemacht, ist dieser verpflichtet, allen Empfängern,
                                denen die Sie betreffenden personenbezogenen Daten offengelegt
                                wurden, diese Berichtigung oder Löschung der Daten oder
                                Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies
                                erweist sich als unmöglich oder ist mit einem
                                unverhältnismäßigen Aufwand verbunden.
                                <br />
                                Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über
                                diese Empfänger unterrichtet zu werden.
                            </li>

                            <li>
                                <h3>Widerspruchsrecht</h3>

                                Sie haben das Recht, aus Gründen, die sich aus ihrer
                                besonderen Situation ergeben, jederzeit gegen die Verarbeitung
                                der Sie betreffenden personenbezogenen Daten, die aufgrund von
                                Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch
                                einzulegen; dies gilt auch für ein auf diese Bestimmungen
                                gestütztes Profiling.
                                <br />
                                Der Verantwortliche verarbeitet die Sie betreffenden
                                personenbezogenen Daten nicht mehr, es sei denn, er kann
                                zwingende schutzwürdige Gründe für die Verarbeitung
                                nachweisen, die Ihre Interessen, Rechte und Freiheiten
                                überwiegen, oder die Verarbeitung dient der Geltendmachung,
                                Ausübung oder Verteidigung von Rechtsansprüchen.
                                <br />
                                Werden die Sie betreffenden personenbezogenen Daten
                                verarbeitet, um Direktwerbung zu betreiben, haben Sie das
                                Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie
                                betreffenden personenbezogenen Daten zum Zwecke derartiger
                                Werbung einzulegen; dies gilt auch für das Profiling, soweit
                                es mit solcher Direktwerbung in Verbindung steht.
                                <br />
                                Widersprechen Sie der Verarbeitung für Zwecke der
                                Direktwerbung, so werden die Sie betreffenden
                                personenbezogenen Daten nicht mehr für diese Zwecke
                                verarbeitet.
                                <br />
                                Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
                                Diensten der Informationsgesellschaft – ungeachtet der
                                Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels
                                automatisierter Verfahren auszuüben, bei denen technische
                                Spezifikationen verwendet werden.
                            </li>

                            <li>
                                <h3>Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h3>

                                Sie haben das Recht, Ihre datenschutzrechtliche
                                Einwilligungserklärung jederzeit zu widerrufen. Durch den
                                Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund
                                der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht
                                berührt.
                            </li>

                            <li>
                                <h3>Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>

                                Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
                                gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf
                                Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem
                                Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder
                                des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht
                                sind, dass die Verarbeitung der Sie betreffenden
                                personenbezogenen Daten gegen die DSGVO verstößt.
                                <br />
                                Die Aufsichtsbehörde, bei der die Beschwerde eingereicht
                                wurde, unterrichtet den Beschwerdeführer über den Stand und
                                die Ergebnisse der Beschwerde einschließlich der Möglichkeit
                                eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        );

export default Datenschutz;