import React from 'react';
import SmallLogo from './SmallLogo';
import ContactForm from './ContactForm';

const Contact = () => (
            <div>
                <SmallLogo />
                <div  style={{display: "block", maxWidth: "800px", marginLeft: "auto", marginRight: "auto"}}>
                <ContactForm />
                </div>
            </div>
        );

export default Contact;
