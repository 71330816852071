import React from 'react';
import pixel from './icons/transparentpixel.png'
import onetick from './icons/onetick.png'
import twoticks from './icons/twoticks.png'
import threeticks from './icons/threeticks.png'

class ServiceTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDetails: ""
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick(title) {
        if (this.state.currentDetails == title) {
            this.setState({currentDetails: ""});
        } else {
            this.setState({currentDetails: title});
        }
    }

    render() {
        return (
            <table className="servicetable">
              <tr><th className="serviceheader" colspan="2">{this.props.title}</th></tr>
              {this.props.services.map(
                      (service) => {return <ServiceRow clickHandler={this.onClick} showDetails={this.state.currentDetails==service.title} service={service} />;}
              )}
            </table>
        );
    }
}

const ServiceDescription = ({description}) => (
            <div dangerouslySetInnerHTML={{__html: description}} />
        );

const ServiceDetails = ({details}) => {
        if (!details) return "";
        return (<div className="servicedetails" dangerouslySetInnerHTML={{__html: details}}/>);
        };

const ServiceKeywords = ({keywords}) => {
        if (!keywords || !keywords.length) return "";
        return (<div className="servicekeywords">
            <ul className="servicekeywords">{keywords.map((keyword) => <li className="servicekeywords">{keyword}</li>)}</ul>
        </div>);
        };

const ServiceRow = ({service, showDetails, clickHandler}) => (
        <tr onClick={clickHandler.bind(this, service.title)}>
            <th className="servicetitle" dangerouslySetInnerHTML={{__html: service.title}} />
            <td className="servicedescription">
            <ServiceKeywords keywords={service.keywords} />
            <ServiceDescription description={service.description} />
            {/*showDetails && <ServiceDetails details={service.details} />*/}
            </td>
        </tr>
        );

export default ServiceTable;
