import React from 'react';

import {
    Route,
//    Redirect,
    Switch,
} from 'react-router-dom';

import Home from './Home';
import Impressum from './Impressum';
import Datenschutz from './Datenschutz';
import Contact from './Contact';
import Profile from './Profile';
import Services from './Services';
//import Notfound from './Notfound';

const Main = () =>
            <div className="content">
                <Switch>
                    {/* <Redirect from="/index.html" to="/" /> */}
                    {/* <Route exact path="/" component={Home} /> */}
                    <Route exact path="/" component={Home} />} />
                    <Route exact path="/leistungen" component={Services} />
                    <Route exact path="/profil" component={Profile} />
                    <Route exact path="/impressum" component={Impressum} />
                    <Route exact path="/datenschutz" component={Datenschutz} />
                    <Route exact path="/kontakt" component={Contact} />
                    {/* <Route component={Notfound} /> */}
                    <Route component={Home} />
                </Switch>
            </div>
        ;

export default Main;
