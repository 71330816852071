
const serviceData = [
    {
        title: "Durchführung<br/>von<br/>Softwareprojekten",
        description: "Mit langjähriger Erfahrung ausgestattet setze ich Ihr Projekt eigenständig um, ohne ihre Ressourcen zu belegen."
            + " Sie behalten dabei in jeder Projektphase den Überblick über den aktuellen Fortschritt.",
        keywords: [
            "Analyse und Beratung",
            "Erarbeitung des Systemkonzepts",
            "Entwicklung der Softwarearchitektur",
            "Implementation",
            "Schlüsselfertige Projektübergabe",
            "Unterstützung oder Durchführung des Regelbetriebs"
        ],
        details: ""
    },
    {
        title: "Begleitung<br/>Ihrer<br/>Softwareprojekte",
        description: "Als externer und unabhängiger Berater statte ich Sie mit zusätzlicher Kompetenz"
            + " im Softwarebereich aus und unterstütze Sie und Ihr Team punktuell oder umfassend in allen"
            + " Phasen Ihres Softwareprojekts. Ich helfe Ihnen Ihr Projekt zu Ihrem Erfolg zu machen.",
        keywords: [
            "Analyse und Beratung zum Fortgang von neuen oder Bestandsprojekten",
            "Überprüfung von neuem oder Bestandscode und Ihrer Softwarearchitektur",
            "Konzeption und Implementation von Projektkomponenten für Ihr Projekt",
            "Migrationsplanung zur Modernisierung oder Ersatz von Legacy-Software",
            "Unterstützung des Projektfortgangs durch agile Teamführung und agiles Projektmanagement"
        ],
        details: ""
    },
    {
        title: "Unterstützung<br/>Ihres<br/>Produktivbetriebs",
        description: "Ich kümmere mich um Ihre Produktivsysteme, Sie haben die Hände frei für ihre Kernkompetenzen.",
        keywords: [
            "Analyse der notwendigen und zur Verfügung stehenden Betriebsinfrastruktur",
            "Planung, Installation und Administration benötigter Serverinfrastruktur (HW, VMs oder Cloud)",
            "Planung, Installation und Administration benötigter IP-Basisdienste, z.B. DNS, SMTP, IMAP, HTTP",
            "Konzeption des Deployments",
            "Planung und Implementation des Monitorings",
            "Laufende Anpassung und Betrieb des Monitorings der Produktivinstanzen",
            "Planung und Umsetzung von Backupkonzepten",
            "Erarbeitung von Notfallplänen"
        ],
        details: "Als IT-Enthusiast betreibe ich seit über 20 Jahren benötigte Infrastruktur selbst. Dies umfasst alle Dienste vom E-Mail-Server über"
            + " Webserver bis hin zur kompletten DevOps-Prozesskette."
    }
];

export default serviceData;
