import React from 'react';
import logo from './SmallLogo.png';

class SmallLogo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    render(){
        if (this.state.loading) {
            return (
                <p>
                    <img
                        style={{display: "block", marginLeft: "auto", marginRight: "auto"}}
                        src={logo}
                        alt=""
                        width="500"
                        height="302"
                        onError={() => this.setState({loading : false})}
                    />
                </p>
            )
        } else {
            return (
                <p>
                    <img
                        style={{display: "block", marginLeft: "auto", marginRight: "auto"}}
                        src={logo}
                        alt="Logo of Kai Fritzowsky Software &amp; Consulting"
                        width="500"
                        height="302"
                    />
                </p>
            )
        }
    }
}

export default SmallLogo;
