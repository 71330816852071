
import {
    ActionTypes
} from './Actions';

// quote reducer
export const quote = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_QUOTE:
            return {
                message: action.message,
                author: action.author
            };
        default:
            return state;
    }
};
