import React from 'react';

// BrowserRouter is the router implementation for HTML5 browsers (vs Native).
// Link is your replacement for anchor tags.
// Route is the conditionally shown component based on matching a path to a URL.
// Switch returns only the first matching route rather than all matching routes.
import {
  BrowserRouter as Router,
//  Link,
//  Route,
//  Switch,
} from 'react-router-dom';

import Header from './Header';
import Main from './Main';

const App = () => {
    if ( !window._routerConfig || !window._routerConfig.basename) {
        window._routerConfig = {basename : ""};
    }
    return (
      // <Router basename="">
      // <Router basename="/fritzows.ky/resources">
      // <Router basename="/develop/fritzows.ky/resources">
      <Router basename={window._routerConfig.basename}>
      <div>
          <Header />
          <Main />
      </div>
          </Router>
      );
    };

export default App;
