import React from 'react';
import pixel from './icons/transparentpixel.png'
import onetick from './icons/onetick.png'
import twoticks from './icons/twoticks.png'
import threeticks from './icons/threeticks.png'

/*
 * Format must be like this:
 * 
const categorizedSkills = [
    {
        category: "Category 1",
        skills: [
            {
                skill: "Skill 1/1",
                level: "Level 1/1",
                comment: "Comment 1/1"
            },
            {
                skill: "Skill 1/2",
                level: "Level 1/2",
                comment: "Comment 1/2"
            }
            , {...}, ... // more skills of "Category 1"
        ]
    },
    {
        category: "Category 2",
        skills: [
            {
                skill: "Skill 2/1",
                level: "Level 2/1",
                comment: "Comment 2/1"
            }
            , {...}, ... // more skills of "Category 2"
        ]
    }
    , {...}, ... // more categories
];
*/

const SkillTable = ({title, skills}) => (
    <table className="skilltable">
        <tr><th className="skillheader" colspan="4">{title}</th></tr>
        {skills.map(CategoryFragment)}
    </table>
);

const CategoryFragment = ({category, skills}) => {
        var [firstSkill, ...otherSkills] = skills;
        return (
        <React.Fragment>
        <tr>
            <th className="skillcategory" rowspan={Object.keys(skills).length} dangerouslySetInnerHTML={{__html: category}} />
            <SkillFragment {...firstSkill} />
        </tr>
        {otherSkills.map((skill) => (<tr><SkillFragment {...skill} /></tr>))}
        </React.Fragment>
        );
       };

const SkillFragment = ({icon, skill, level, comment}) => (
        <React.Fragment>
        <td className="skill"><Icon icon={icon} /><div className="skilllabel" dangerouslySetInnerHTML={{__html: skill}} /></td>
                <td className="skilllevel"><SkillLevel level={level} /></td>
                <td className="skillcomment" dangerouslySetInnerHTML={{__html: comment}} />
        </React.Fragment>
        );

const SkillLevel = ({level}) => {
    var img = onetick;
    switch (level.length)  {
        case 1: img = onetick; break;
        case 2: img = twoticks; break;
        case 3: img = threeticks; break;
        default:
            img = threeticks;
            break;
    }
    return (
        <img className="skillicon"
            style={{display: "block", marginLeft: "auto", marginRight: "auto"}}
            src={img}
            alt=""
        />);
};

const Icon = ({icon}) => {
    var img = icon;
    if (!img) {
        img = pixel;
    }
    return (
        <img className="skilllabelicon"
            style={{display: "block", marginLeft: "auto", marginRight: "auto"}}
            src={img}
            alt=""
        />);
};

export default SkillTable;
