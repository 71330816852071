import React from 'react';

import SmallLogo from './SmallLogo';
import QuoteContainer from './QuoteContainer';

const Home = () => (
            <div>
                <SmallLogo />
                <QuoteContainer />
            </div>
        );

export default Home;
