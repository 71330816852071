
export const ActionTypes = {
    UPDATE_QUOTE: "UPDATE_QUOTE"    
};

// quote action creators
export const updateQuote = ({message, author}) =>
({
   type: ActionTypes.UPDATE_QUOTE,
   message,
   author
});

// selectors
export const getQuote = state => state.quote;
export const getQuoteMessage = state => getQuote(state).message;
export const getQuoteAuthor = state => getQuote(state).author;
